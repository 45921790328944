@import 'reset';
@import 'colors';

body {
  background-color: $dark-blue;
  font-family: 'Playfair Display', serif;
  font-size: 12px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Montserrat', sans-serif;
  }
}

#modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content {
    border-radius: 5px;
    margin: 15% auto;
    background: linear-gradient($blue-tinted-white, $light-orange);
    padding: 20px;
    border: 1px solid #888;
    width: 50rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;

    /* The Close Button */
    .close {
      color: $dark-blue;
      font-size: 3rem;
      font-weight: bold;
      transition: all 0.2s ease-in-out;
    }

    .close:hover,
    .close:focus {
      color: $dark-blue;
      text-decoration: none;
      cursor: pointer;
      transform: scale(1.1);
    }

    div {
      margin-left: 1rem;
    }

    figure {
      margin: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      figcaption {
        margin-top: 0.5rem;
      }
    }

    label {
      margin-top: 1rem;
      text-align: left;
      display: block;
    }

    h1 {
      text-shadow: 2px 2px 0px #ef7c00;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }

    h3,
    h4 {
      margin: 0rem;
      font-weight: 500;
    }

    img {
      width: 20rem;
    }

    ul {
      text-align: left;
    }
  }
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100vh;
  vertical-align: top;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;

  .svg-content {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;

    #timeline {
      fill: $blue-tinted-white;
      font-family: 'Montserrat', sans-serif;
    }

    #timeline text {
      fill: $blue-tinted-white;
      stroke: none;
    }

    .eventCircle text {
      text-anchor: middle;
      fill: $dark-orange;
      stroke: none;
    }
  }
}
